import { useState } from "react";
import { ReactComponent as InfoIcon } from "../../../assets/svg/icons/info.svg";
import { Loading } from "../../Loading/Index";
import InfoFailSync from "../../Tasks/ViewTasks/InfoFailSync";
import "./styles.css"
import { formatDateToCustomFormat } from "../../../utils/date/formatedDate";

type FailSyncTableProps = {
  listTable: IFailSync[];
  isLoading: boolean;
};


export default function FailSyncTable({
  listTable,
  isLoading
}: FailSyncTableProps) {
  const [selectedId, setSelectedId] = useState(0);

  return (
    <>
      {listTable.length != 0 ?
        <>
          <section className="tableColumn">
            <div>Usuário</div>
            <div>Data</div>
            <div />
          </section>

          <section className="tableViewRows">
            <Loading visible={isLoading} />

            {listTable.map(rowData => {
              const date = new Date(rowData.date);

              return (
                <div className="tableRow">
                  <div>
                    {rowData.name}
                  </div>
                  <div>
                    {date ? formatDateToCustomFormat(date) : ""}
                  </div>
                  <div style={{
                    cursor: "pointer",
                  }}>
                    <InfoIcon
                      onClick={() => {
                        setSelectedId(rowData.id)
                      }}
                    />
                  </div>
                </div>
              )
            }
            )}
          </section>
        </>
        :
        <div className="emptyTable_container">
          <span>Nenhum resultado encontrado.</span>
        </div>
      }
      <InfoFailSync
        selected={selectedId}
        setSelectedId={setSelectedId}
      />
    </>
  );
}