import { CSSProperties, useEffect, useState } from "react";
import { Modal } from "../../../Modal/Default/Index";
import { RoundedCloseButton } from "../../../Buttons/RoundedCloseButton/Index";
import { FaRegCopy } from "react-icons/fa";
import axios from "../../../../services/axios";
import ReactJson from 'react-json-view'
import "./styles.css"
import { formatDateToCustomFormat } from "../../../../utils/date/formatedDate";

interface InfoFailSyncProps {
    selected: number;
    setSelectedId: (e: number) => void;
}

interface FailSyncResponse {
    id: number;
    date: string;
    request: any;
    response: any;
}

export default function InfoFailSync({
    selected,
    setSelectedId
}: InfoFailSyncProps) {
    const createEvaluatorModalStyle = {
        padding: "50px",
        width: "800px",
        height: "fit-content",
        maxHeight: "800px",
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    } as CSSProperties;

    const [responseFailSync, setResponseFailSync] = useState<FailSyncResponse | undefined>(undefined);

    useEffect(() => {
        if (selected != 0)
            axios.get(`/sync/failures/${selected}`)
                .then(response => {
                    setResponseFailSync(response.data);
                })
    }, [selected]);

    if (!responseFailSync) return null;
    const date = new Date(responseFailSync?.date)
    return (
        <Modal
            visible={selected != 0}
            contentStyle={createEvaluatorModalStyle}
        >
            <section>
                <header className="headerInfoEvaluation">
                    <div>
                        <h1>
                            {`Sincronismo `}
                            {formatDateToCustomFormat(date)}
                        </h1>
                    </div>
                    <div>
                        <RoundedCloseButton
                            onClick={() => setSelectedId(0)}
                        />
                    </div>
                </header>

                <div>
                    <div>
                        <div className="failSync-rowContent">
                            <h2>Requisição</h2>
                        </div>
                        <pre className="failSync-jsonContent">
                            <ReactJson src={JSON.parse(responseFailSync.request)} enableClipboard={false} displayObjectSize={false} />
                        </pre>
                    </div>

                    <div>
                        <div className="failSync-rowContent">
                            <h2>Resposta</h2>
                        </div>
                        <pre className="failSync-jsonContent">
                            <ReactJson src={JSON.parse(responseFailSync.response)} enableClipboard={false} displayObjectSize={false} />
                        </pre>
                    </div>
                    <button
                        className="failSync-clipBoard"
                        onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify({
                                ...responseFailSync,
                                request: JSON.parse(responseFailSync.request),
                                response: JSON.parse(responseFailSync.response)
                            }, null, 2));

                            const button = document.getElementById('copy-request');
                            if (button) {
                                button.innerText = 'Copiado';
                                setTimeout(() => {
                                    button.innerText = 'Copiar informações';
                                }, 5000);
                            }
                        }}
                    >
                        <span id="copy-request">
                            Copiar informações
                        </span>
                        <FaRegCopy size={20} />
                    </button>
                </div>
            </section>
        </Modal>
    )
}
