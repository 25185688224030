import './styles.css'
import {
  ChangeEvent,
  CSSProperties,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import AnimateHeight from 'react-animate-height'
import { ReactComponent as EyeOpen } from '../../../../../assets/svg/icons/eyeClosed.svg'
import { ReactComponent as EyeClosed } from '../../../../../assets/svg/icons/eye.svg'
import { ReactComponent as Trash } from '../../../../../assets/svg/icons/trashOnboarding.svg'
import { ReactComponent as PenIcon } from '../../../../../assets/svg/icons/penEdit.svg'
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg'
import { Select } from '../../../../Inputs/Select/Index'
import { TextInput } from '../../../../Inputs/TextInputs/TextInput/Index'
import { IOptionsProps } from '../../../../../@types/reactSelect/IOption'
import { IAreas } from '../../../../../@types/app/IArea'
import { useOnboarding } from '../../../../../hooks/useOnboarding'
import { ButtonWithChildren } from '../../../../Buttons/ButtonWithChildren/Index'
import { Modal } from '../../../../Modal/Default/Index'
import { DrawPolygon } from '../../../../GoogleMaps/DrawPolygon/Index/Index'
import { IFarm } from '../../../../../@types/app/IFarm'
import { useGoogleMaps } from '../../../../../hooks/useGoogleMaps'
import {
  IPositionProps,
  IPositionsProps,
} from '../../../../../@types/GoogleMaps/IPositionProps'
import Constants from '../../../../../constants/Index'
import { IExistingMaps } from '../../../../../@types/GoogleMaps/IExistingMaps'

import { Polygon } from 'react-leaflet'
import {
  FlyToBounds,
  FlyToBoundsRefProps,
} from '../../../../Leaflet/FlyToBounds/Index'
import { LeafletStaticMap } from '../../../../Leaflet/LeafletStaticMap/Index'
import { ICreateArea, IFarmArea } from '../Index'
import { TextInputWithUnit } from '../../../../Inputs/TextInputs/TextInputWithUnit'
import { Scope } from '@unform/core'

export type IFieldsNames =
  | 'name'
  | 'initials'
  | 'manager_user_id'
  | 'map_coords'
  | 'acreage'

type AreaProps = {
  formRef: MutableRefObject<HTMLFormElement | undefined>
  managers: IOptionsProps
  isDeletable?: boolean
  areaId: number
  farmId: number
  initData: ICreateArea
  mapAreas?: IPositionsProps
  handleChangesAreas?: (e: any) => void
  onHandleSuccess?: (polygon?: IPositionProps) => void
  onRemoveArea?: (uuid: string) => void
  isEdition?: boolean
  disableEditFields: Array<IFieldsNames>
  farmInitials: string
  farm: IFarmArea
}

const contentStyleModal = {
  width: '95%',
  height: '95vh',
  maxWidth: '1350px',
  maxHeight: '937px',
  borderRadius: '20px',
  overflow: 'hidden',
} as CSSProperties

export function FormArea({
  isDeletable,
  managers,
  formRef,
  areaId,
  farmId,
  initData,
  handleChangesAreas,
  onRemoveArea,
  isEdition = false,
  disableEditFields,
  farmInitials,
  farm: currentFarm,
}: AreaProps) {
  const { setToolMode, setExistingMaps } = useGoogleMaps()

  const flytoBoundsRef = useRef<FlyToBoundsRefProps>()

  // Isso diz quando mostrar a Area
  const [visibleArea, setVisibleArea] = useState<'auto' | 0>(0)

  const [farm, setFarm] = useState<IFarm | undefined>(undefined)
  const [area, setArea] = useState<any | undefined>(undefined)

  const [existentingArea, setExistentingArea] = useState<
    IPositionProps | undefined
  >()

  const [errorMap, setErrorMap] = useState<string | undefined>(undefined)

  const [readOnly, setIsReadOnly] = useState<boolean>(true)

  const [acreageMask, setAcreageMask] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string>('')

  // Isso captura qualquer tipo de alteração nos Inputs repassados
  const onChangeAnywhereInput = useCallback(
    (event: any) => {
      if (handleChangesAreas && event) handleChangesAreas(event)
    },
    [handleChangesAreas],
  )

  const closeModal = () => setFarm(undefined)

  // Isso esconde ou mostra a Area digitada
  const handleVisibleArea = () =>
    setVisibleArea((prev) => (prev === 0 ? 'auto' : 0))

  // Isso lida com a sigla da Area
  function handleTitleArea(
    event: ChangeEvent<HTMLInputElement> | string,
    noInputInitials?: boolean,
  ) {
    if (typeof initData.id == 'string') {
      //Isso diz que nao se deve alterar a sigla se o usuário quiser inerir manualmente
      const self = `farms[${farmId}].areas[${areaId}]`
      if (!readOnly && typeof event === 'object') return
      formRef.current?.setErrors({ [`${self}.initials`]: '' })
      const value = typeof event === 'string' ? event : event.target.value
      let currentValue = noInputInitials ? value.charAt(0) : value
      if (noInputInitials) {
        if (value.includes(' ')) {
          // Se o valor digitado tem espaços colocamos uma letra de cada sentença
          const initials = value.split(' ')
          currentValue = value.charAt(0) + initials[1].charAt(0)
        } else currentValue += value.charAt(1) // Senão colocamos a primeira e segunda letra da senteça
      }
      const upperCase = currentValue.toString().toUpperCase()
      if (upperCase.length < 4)
        formRef.current?.setFieldValue(`${self}.initials`, upperCase)
      else
        formRef.current?.setFieldValue(
          `${self}.initials`,
          upperCase.substring(0, 3),
        )
    }
  }

  // Isso habilita ou desabilita a edição de sigla
  const handleInputOnlyRead = () => {
    if (!readOnly) {
      const nameInput = formRef.current?.getFieldRef(
        `farms[${farmId}].areas[${areaId}].name`,
      )
      if (nameInput.current.value)
        handleTitleArea(nameInput.current.value, true)
    }
    setIsReadOnly((prevState) => !prevState)
  }

  function handleDrawArea() {
    const farm = {
      uuid: currentFarm.id,
      name: currentFarm.name,
      initials: currentFarm.initials,
      map_coords: currentFarm.coords,
    } as IFarm

    if (!!farm) {
      setToolMode('draw')
      setFarm(farm)
      const form: IAreas = formRef.current?.getData().farms[farmId].areas
      if (!!form) {
        const response = form
          .filter((f) => f.uuid !== initData.id)
          .map(({ map_coords, uuid, initials }) => ({
            uuid,
            coords: map_coords?.coords,
            nameMap: `${farm.initials || ''}-${initials || ''}`,
          }))
        setExistingMaps(response as IExistingMaps)
      }
    }
  }

  function handleMaskAcreage(text: string) {
    switch (text.replace('.', '').length) {
      case 3:
        setAcreageMask('99.99999')
        break
      case 4:
        setAcreageMask('999.9999')
        break
      case 5:
        setAcreageMask('9999.9999')
        break
      case 6:
        setAcreageMask('99999.9999')
        break
      case 7:
        setAcreageMask('999999.99999')
        break
      case 8:
        setAcreageMask('9999999.99')
        break
      default:
        setAcreageMask('9999999.99')
        break
    }
  }

  function discardAreaPolygon() {
    setFarm(undefined)
    setExistentingArea(undefined)
    handleChangesAreas && handleChangesAreas(true)
  }

  function handleSuccess(polygon?: IPositionProps) {
    const areas: IAreas = formRef.current?.getData().farms[farmId].areas
    if (!!areas) {
      const areaFounded = areas.find(
        (area) => area.map_coords?.uuid === polygon?.uuid,
      )
      if (!!areaFounded && areaFounded.map_coords)
        if (areaFounded.map_coords.coords.length === 0) {
          setExistentingArea(undefined)
        } else {
          setExistentingArea(areaFounded.map_coords)
          const form: IAreas = formRef.current?.getData().farms[farmId].areas
          if (!!farm && !!form) {
            const formFiltered = form
              .filter((f) => f.uuid !== initData.farmId)
              .map((a) => ({
                ...a.map_coords,
                nameMap: `${farm.initials}-${areaFounded.initials}`,
              }))
            setExistingMaps(formFiltered as IExistingMaps)
          }
        }
      setFarm(undefined)
      onChangeAnywhereInput(polygon)
    }
  }

  useEffect(() => {
    if (initData) {
      const self = `farms[${farmId}].areas[${areaId}]`
      formRef.current?.setFieldValue(`${self}.uuid`, initData.id)
      formRef.current?.setFieldValue(`${self}.farm_id`, initData.farmId)
      formRef.current?.setFieldValue(`${self}.name`, initData.name)
      formRef.current?.setFieldValue(`${self}.initials`, initData.initials)
      formRef.current?.setFieldValue(
        `${self}.manager_user_id`,
        initData.managerUser,
      )
      formRef.current?.setFieldValue(`${self}.isNew`, initData.isNew)
      formRef.current?.setFieldValue(`${self}.acreage`, initData.acreage)
      formRef.current?.setFieldValue(
        `${self}.indoor_grazing_time`,
        initData?.indoor_grazing_time,
      )
      formRef.current?.setFieldValue(
        `${self}.outdoor_grazing_time`,
        initData?.outdoor_grazing_time,
      )
      if (initData.coords.length > 0) {
        const mapCoords = { uuid: initData.farmId, coords: initData.coords }
        formRef.current?.setFieldValue(`${self}.map_coords`, mapCoords)
        setExistentingArea(mapCoords)
      }
      setFarm(undefined)
      setVisibleArea(initData.openForm ? 'auto' : 0)
      handleMaskAcreage(initData.acreage)
    }

    setArea(
      formRef.current?.getData().farms
        ? formRef.current?.getData().farms[farmId]?.areas
        : formRef.current?.getData().farms,
    )
  }, [initData])

  useEffect(() => {
    if (disableEditFields.length > 0)
      setIsReadOnly(() => disableEditFields.some((f) => f === 'initials'))
  }, [disableEditFields])

  function blurAcreage(event: any) {
    if (event.target.value.length > 0 && event.target.value.length <= 3) {
      formRef.current?.setFieldValue(
        `farms[${farmId}].areas[${areaId}].acreage`,
        event.target.value.length == 2
          ? event.target.value + '00'
          : event.target.value + '0',
      )
      onChangeAnywhereInput(true)
    }
  }

  return (
    <section className="area__container">
      <div className="area__title--container">
        <h3>Retiro {areaId + 1}</h3>
        <div>
          {(initData.isNew || (!isEdition && isDeletable)) && (
            <button
              type="button"
              onClick={() => onRemoveArea && onRemoveArea(initData.id)}
              style={{ marginRight: 15 }}
              id="bt_excluir"
            >
              <Trash />
            </button>
          )}
          <button type="button" onClick={handleVisibleArea}>
            {visibleArea ? <EyeOpen /> : <EyeClosed />}
          </button>
        </div>
      </div>
      <AnimateHeight height={visibleArea}>
        <section className="area__content">
          <div className="area__form">
            <section className="area__textinput--container">
              <TextInput name={'uuid'} hidden={true} />
              <TextInput name={'isNew'} hidden={true} />
              <TextInput name={'farm_id'} hidden={true} />
              <label>*Nome do Retiro</label>
              <TextInput
                id={`name-F${farmId}-R${areaId}`}
                placeholder="Nome do Retiro"
                onChange={(i) => handleTitleArea(i.target.value, true)}
                name="name"
                maxLength={120}
                handleAnywhereChanges={onChangeAnywhereInput}
                disabled={
                  disableEditFields &&
                  disableEditFields.some((f) => f === 'name')
                }
              />
            </section>

            <section className="area__textinput--container">
              <label>*Quantos hectares de área útil total?</label>
              <TextInputWithUnit
                id={`acreage-` + farmId + `-` + areaId}
                onChange={(text) => handleMaskAcreage(text.target.value)}
                mask={acreageMask}
                unit="ha"
                handleAnywhereChanges={onChangeAnywhereInput}
                name="acreage"
                disabled={
                  !initData.isNew &&
                  disableEditFields &&
                  disableEditFields.some((f) => f === 'acreage')
                }
                returnAsNumber
                onBlur={blurAcreage}
              />
            </section>

            <section className="area__textinput--container">
              <label>*Sigla</label>
              <section
                className={`area__textinput--initials ${readOnly ? 'initials__read' : ''
                  } ${error ? 'isInvalid' : ''}`}
              >
                <label htmlFor={`initials-F${farmId}-R${areaId}`}>
                  {farmInitials}-
                </label>
                <TextInput
                  id={`initials-F${farmId}-R${areaId}`}
                  disabled={
                    readOnly ||
                    (!initData.isNew &&
                      disableEditFields &&
                      disableEditFields.some((f) => f === 'initials'))
                  }
                  name="initials"
                  getMessageError={(error) => setError(error)}
                  onInput={(i) => handleTitleArea(i.currentTarget.value)}
                  handleAnywhereChanges={onChangeAnywhereInput}
                />
              </section>
              {error && <small>{error}</small>}
              {(initData.isNew ||
                !disableEditFields ||
                !disableEditFields.some((f) => f === 'initials')) && (
                  <button
                    id={`readonly-initials-F${farmId}-R${areaId}`}
                    className="area__button--readonly"
                    onClick={handleInputOnlyRead}
                    type="button"
                  >
                    {!readOnly ? 'Usar sigla automática' : 'Alterar sigla'}
                  </button>
                )}
            </section>

            <div className="farm__textinput--container">
              <label>*Tempo máximo COM gado</label>
              <Select
                noOptionsMessage={() => (
                  <small className="noContent--msg">-</small>
                )}
                handleAnywhereChanges={onChangeAnywhereInput}
                id={`select-indoor-F${farmId}-R${areaId}`}
                placeholder="Selecione..."
                defaultMenuIsOpen={false}
                closeMenuOnSelect={true}
                isSearchable={true}
                options={Constants.DAYS_OPTIONS}
                name="indoor_grazing_time"
              />
            </div>

            <div className="farm__textinput--container">
              <label>*Tempo máximo SEM gado</label>
              <Select
                noOptionsMessage={() => (
                  <small className="noContent--msg">-</small>
                )}
                handleAnywhereChanges={onChangeAnywhereInput}
                id={`select-outdoor-F${farmId}-R${areaId}`}
                placeholder="Selecione..."
                defaultMenuIsOpen={false}
                closeMenuOnSelect={true}
                isSearchable={true}
                options={Constants.DAYS_OPTIONS}
                name="outdoor_grazing_time"
              />
            </div>

            <div className="area__textinput--container">
              <label>*Responsável do Retiro</label>
              <Select
                noOptionsMessage={() => (
                  <small className="noContent--msg">
                    Não foram encontrados perfis
                  </small>
                )}
                id={`manager_user_id-F${farmId}-R${areaId}`}
                placeholder="Selecione..."
                defaultMenuIsOpen={false}
                closeMenuOnSelect={true}
                name="manager_user_id"
                isSearchable={true}
                options={managers}
                handleAnywhereChanges={onChangeAnywhereInput}
                isDisabled={
                  !initData.isNew &&
                  disableEditFields &&
                  disableEditFields.some((f) => f === 'manager_user_id')
                }
              />
            </div>
          </div>
          {visibleArea === 'auto' && (
            <div
              className={`area__drawarea--container ${!!existentingArea ? 'hasPolygon' : ''
                }`}
              style={{
                marginLeft: 75
              }}
            >
              <div>
                {existentingArea && (
                  <LeafletStaticMap>
                    <FlyToBounds
                      ref={flytoBoundsRef}
                      fitCoords={existentingArea.coords}
                    >
                      <Polygon
                        positions={existentingArea.coords}
                        color={Constants.LEAFLET_NORMAL_BLUE.color}
                        fillColor={Constants.LEAFLET_NORMAL_BLUE.fillColor}
                        fillOpacity={Constants.LEAFLET_NORMAL_BLUE.fillOpacity}
                      />
                    </FlyToBounds>
                  </LeafletStaticMap>
                )}
                {(initData.isNew ||
                  !disableEditFields ||
                  !disableEditFields.some((f) => f === 'map_coords')) && (
                    <ButtonWithChildren
                      theme={!existentingArea ? 'dark' : 'info'}
                      onClick={handleDrawArea}
                    >
                      <div className="buttondraw__container">
                        <PenIcon />
                        {!existentingArea && <h3>Desenhe o retiro</h3>}
                      </div>
                    </ButtonWithChildren>
                  )}
              </div>

              {errorMap && <span>{errorMap}</span>}
            </div>
          )}
        </section>
      </AnimateHeight>

      <Modal visible={!!farm} contentStyle={contentStyleModal}>
        <header className="drawpolygon__header">
          <ButtonWithChildren theme="light" onClick={closeModal}>
            <CloseIcon />
          </ButtonWithChildren>
        </header>
        <DrawPolygon
          farm={farm}
          onHandleSuccess={handleSuccess}
          getMessageError={(error) => setErrorMap(error)}
          onHandleDiscardChanges={discardAreaPolygon}
          areas={area}
        />
      </Modal>
    </section>
  )
}
