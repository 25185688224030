import React from 'react';
import { ReactComponent as BackIcon } from "../../../../assets/svg/icons/back.svg";
import './style.css';
import { useFilter } from '../../../../hooks/useFilter';
import { ShowData } from '..';
import { useEditOnboarding } from '../../../../hooks/useEditOnboarding';
import { useAuth } from '../../../../hooks/useAuth';

export function SystemManager() {
    const { systemManager, sideBarOptionSelected, setSystemManager } = useFilter();
    const {
        setFilterSelected,
        setOptionForm,
    } = useEditOnboarding();

    return (
        sideBarOptionSelected == "MANAGER_SYSTEM" && systemManager === null
            ?
            <div className="templateDataManager">
                <h2 style={{ height: 58 }}>Gerenciar sistema</h2>
                <div className="delimiter" style={{ marginBottom: 20 }} />
                <div className="templateDataManager__container">
                    <div>
                        <div className="templateDataManager__container--item">
                            <h2>Mapa</h2>
                            <button className="templateDataManager__container--container"
                                onClick={() => setSystemManager('PROPERTIES_AREAS')}
                            >
                                <span>Propriedades e Áreas</span>
                                <BackIcon className='icon' />
                            </button>
                            <button className="templateDataManager__container--container"
                                onClick={() => setSystemManager('TASK_EVALUATION')}
                            >
                                <span>Monitoramento de tarefas</span>
                                <BackIcon className='icon' />
                            </button>
                        </div>
                        <div className="templateDataManager__container--item">
                            <h2>Animais</h2>
                            <button className="templateDataManager__container--container"
                                onClick={() => setSystemManager('CREATE_BATCH')}
                            >
                                <span>Criação de lotes</span>
                                <BackIcon className='icon' />
                            </button>
                            <button className="templateDataManager__container--container"
                                onClick={() => setSystemManager('PASTURE_MANAGEMENT')}
                            >
                                <span>Manejo de Pasto</span>
                                <BackIcon className='icon' />
                            </button>
                            <button className="templateDataManager__container--container"
                                onClick={() => setSystemManager('CATTLE_MAP')}
                            >
                                <span>Categorias e pesos</span>
                                <BackIcon className='icon' />
                            </button>
                        </div>
                    </div>

                    <div>
                        <div className="templateDataManager__container--item">
                            <h2>Tipos e materiais</h2>
                            <button className="templateDataManager__container--container"
                                onClick={() => setSystemManager('PRODUCTS')}
                            >
                                <span>Produtos</span>
                                <BackIcon className='icon' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            :
            <ShowData />
    );
};

