import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { ReactComponent as EyeOpen } from "../../../../../assets/svg/icons/eyeClosed.svg";
import { ReactComponent as EyeClosed } from "../../../../../assets/svg/icons/eye.svg";
import AnimateHeight from "react-animate-height";
import { ButtonOnlyTitle } from "../../../../Buttons/ButtonOnlyTitle/Index";
import { useOnboarding } from "../../../../../hooks/useOnboarding";
import { TextInput } from "../../../../Inputs/TextInputs/TextInput/Index";
import { Select } from "../../../../Inputs/Select/Index";
import {
  IOptionProps,
  IOptionsProps,
} from "../../../../../@types/reactSelect/IOption";
import { SelectWithInput } from "../../../../Inputs/SelectWithInputText/Index";
import axios from "../../../../../services/axios";
import { ICategory } from "../../../../../@types/app/ICategory";
import { components } from "react-select";
import { IMicroArea } from "../../../../../@types/app/IMicroArea";
import { ILotCategory } from "../../../../../@types/app/ILot";

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props} className="lot__select--category">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        {props.label}
        <br />
        <span>{props.data.info}</span>
      </components.Option>
    </div>
  );
};

type LotsProps = {
  formRef: MutableRefObject<HTMLFormElement | undefined>;
  microAreasMap: Map<string, IMicroArea | undefined>;
  microAreaAvailable: IOptionsProps;
  farm: any;
  area: any;
  batch: any;
  keyFarm: number;
  keyArea: number;
  keyBatch: number;
  onlyRead?: boolean;
  isDeletable: boolean;
  onEventRemove: (uuid: number) => void;
  updateAvailableMicroArea: () => void;
  handleAnyChanges: (index?: number) => void;
  batchSelected: (id: number) => void;
};

export function Batch({
  farm,
  area,
  microAreasMap,
  microAreaAvailable,
  keyFarm,
  keyArea,
  keyBatch,
  formRef,
  batch,
  isDeletable,
  onlyRead = false,
  onEventRemove,
  updateAvailableMicroArea,
  handleAnyChanges,
  batchSelected,
}: LotsProps) {
  const { payload } = useOnboarding();
  const eventRemove = () => onEventRemove && onEventRemove(keyBatch);
  const [visibleFarm, setVisibleFarm] = useState<"auto" | 0>("auto");
  const [readOnly, setIsReadOnly] = useState<boolean>(true);
  const [messageInitials, setMessageInitials] = useState<string>("");
  const [categoriesSelected, setCategoriesSelected] = useState<Array<number>>(
    []
  );
  const [categories, setCategories] = useState<
    Map<number | undefined, ICategory>
  >(new Map());
  const [existedCategory, setExistedCategory] = useState<
    Map<number, ILotCategory>
  >(new Map());

  function handleVisibleFarm() {
    setVisibleFarm((prev) => (prev === 0 ? "auto" : 0));
  }

  function handleInputOnlyRead() {
    let value = "";
    if (!readOnly) value = `LT${(keyBatch + 1).toString().padStart(3, "0")}`;
    formRef.current?.setFieldError(
      `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].initials`,
      ""
    );
    formRef.current?.setFieldValue(
      `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].initials`,
      value
    );
    setIsReadOnly((prevState) => !prevState);
    handleAnyChanges();
  }

  useEffect(() => {
    setTimeout(() => handleAnyChanges(), 1000)
  }, []);

  async function getCategories() {
    if (payload.evolutions) {
      const currentMonth = new Date().getMonth() + 1;

      setCategories(() => {
        const evolutionsMap = new Map(
          payload.evolutions
            .filter((value) => value.month == currentMonth)
            .map((evolution) => {
              const category = payload.categories.get(evolution.category_id);
              const montEvolution = category?.month_evolution.find(
                (element) => element.month == evolution.month
              );
              return [
                category?.id,
                {
                  month: currentMonth,
                  weight: {
                    unit: montEvolution?.weight.unit,
                    value: evolution.weight.value,
                  },
                } as any,
              ];
            })
        ) as Map<number, any>;

        return new Map(
          Array.from(payload?.categories.values()).map((category) => {
            let montEvolution = category?.month_evolution.find(
              (element) => element.month == currentMonth
            );
            const evolutionMonth = evolutionsMap.get(category?.id);
            if (evolutionMonth) {
              montEvolution = evolutionMonth;
            }
            return [
              category?.id,
              {
                id: category?.id,
                name: category?.name,
                age_description: category?.age_description,
                month: currentMonth,
                unit: montEvolution?.weight.unit,
                middle_weight: [montEvolution?.weight.value],
              } as ICategory,
            ];
          })
        ) as Map<number, any>;
      });
    } else {
      axios.get("/evolutions").then(({ data }) => {
        const currentMonth = new Date().getMonth() + 1;
        var values = data.map((type: any) =>
          type.categories.map((category: any) => {
            var json = {
              id: category.id,
              name: category.name,
              age_description: category.age_description,
              month: currentMonth,
              unit: category.month_evolution[currentMonth - 1].weight.unit,
              middle_weight:
                category.month_evolution[currentMonth - 1].weight.value,
            };
            return json;
          })
        );
        setCategories(
          new Map(
            values.flat().map((value: any) => {
              return [value.id, value];
            })
          )
        );
      });
    }
  }

  function handleCategoryChange(option: any) {
    const selected = option.map((item: IOptionProps) => parseInt(item.value));
    if (selected === null || selected.length == 0) {
      formRef.current?.setFieldValue(
        `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].total_ua`,
        0
      );
      formRef.current?.setFieldValue(
        `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].batch_capacity_rate`,
        0
      );
    }
    setCategoriesSelected(selected);
  }

  function handleTitleLot() {
    formRef.current?.setErrors({
      [`farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].initials`]: "",
    });
    let batchInitials = `LT${(keyBatch + 1).toString().padStart(3, "0")}`;
    formRef.current?.setFieldValue(
      `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].initials`,
      batchInitials.toString().toLocaleUpperCase()
    );
  }

  const selectMicroArea = useCallback(
    () => updateAvailableMicroArea(),
    [updateAvailableMicroArea]
  );

  function handleUaTotalChanges() {
    const categories =
      formRef.current?.getData().farms[keyFarm].areas[keyArea].lots[keyBatch]
        .categories;

    if (categories) {
      let sum = 0;

      categories.forEach((category: any) => {
        sum += (category.quantity * category.middle_weight) / 450;
      });

      formRef.current?.setFieldValue(
        `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].total_ua`,
        sum.toFixed(2)
      );

    }
  }

  useEffect(() => {
    getCategories();
    handleTitleLot();
    selectMicroArea();
  }, [, batch]);

  useEffect(() => {
    setValuesPayload();
  }, [categories]);

  async function setValuesPayload() {
    formRef.current?.getFieldRef(
      `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].current_micro_area`
    )
      .setValue(null);
    formRef.current?.setFieldValue(
      `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].categoryMap`, new Array<IOptionProps>()
    );
    let categoriesList = new Array<IOptionProps>();
    let existedCategory = new Map();
    await batch?.categories?.forEach((category: any) => {
      if (category) {
        const categotyID = parseInt(category.id);
        const categoryFound = categories.get(categotyID);
        if (categoryFound) {
          existedCategory = existedCategory.set(categotyID, category);
          const option = {
            label: categoryFound.name,
            value: categoryFound.id + "",
            info: `${categoryFound.middle_weight} ${categoryFound.unit} - ${categoryFound.age_description}`,
          } as IOptionProps;
          categoriesList.push(option);
        }
      }
    });
    setExistedCategory(existedCategory);

    let routesMicroArea = new Array<IOptionProps>();
    await batch?.micro_area_route_ids?.forEach((microArea: any) => {
      if (microArea) {
        const microAreaFinded = microAreasMap.get(microArea);
        if (microAreaFinded) {
          const option = {
            label: `${farm.initials}-${area.initials}-${microAreaFinded.initials}`,
            value: microAreaFinded.uuid,
          } as IOptionProps;
          routesMicroArea.push(option);
        }
      }
    });
    formRef.current?.setFieldValue(
      `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].categoryMap`,
      categoriesList
    );
    if (batch.initials) {
      formRef.current?.setFieldValue(
        `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].initials`,
        batch.initials
      );
    }
    formRef.current?.setFieldValue(
      `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].micro_area_route_ids`,
      []
    );
    formRef.current?.setFieldValue(
      `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].micro_area_route_ids`,
      routesMicroArea
    );
    if (batch?.current_micro_area) {
      formRef.current?.setFieldValue(
        `farms[${keyFarm}].areas[${keyArea}].lots[${keyBatch}].current_micro_area`,
        batch.current_micro_area
      )
    }
    handleChanges(true);
  }

  const handleChanges = useCallback(
    (change?: any) => {
      if (change) {
        batchSelected(
          formRef.current
            ?.getData()
            .farms[keyFarm].areas.map((area: any) => area.lots)
            .flat()
            .findIndex(
              (lot: any) =>
                lot.keyFarm == keyFarm &&
                lot.keyArea == keyArea &&
                lot.keyBatch == keyBatch
            )
        );
        handleAnyChanges && handleAnyChanges();
      }
    },
    [handleAnyChanges]
  );

  return (
    <div
      style={{ marginLeft: 20 }}
      id={`batch-${keyFarm}-${keyArea}-${keyBatch}`}
    >
      <div className="microarea__title--container">
        <h3>Lote {keyBatch + 1}</h3>
        <button type="button" onClick={handleVisibleFarm}>
          {visibleFarm ? <EyeOpen /> : <EyeClosed />}
        </button>
      </div>

      <AnimateHeight height={visibleFarm}>
        <TextInput hidden={true} value={!onlyRead + ""} name="isNew" />
        <TextInput hidden={true} value={farm.uuid} name="farm_id" />
        <TextInput hidden={true} value={area.uuid} name="area_id" />
        <TextInput hidden={true} value={keyFarm} name="keyFarm" />
        <TextInput hidden={true} value={keyArea} name="keyArea" />
        <TextInput hidden={true} value={keyBatch} name="keyBatch" />
        <section className="lot__textinput--container">
          <label>*Sigla</label>
          <div
            className={`textinput__initials--container ${!readOnly ? "lot__textinput--isEditable" : ""
              }`}
          >
            <label htmlFor={`textinput-initials-${keyBatch}`}>
              {farm && area ? `${farm.initials + "-" + area.initials}-` : false}
            </label>
            <TextInput
              hidden={true}
              value={farm && area ? farm.initials + "-" + area.initials : ""}
              name="farmAreaInitials"
            />
            <TextInput
              id={`textinput-initials-${keyBatch}`}
              readOnly={readOnly}
              name="initials"
              mask="*****"
              getMessageError={(text) => setMessageInitials(text)}
              handleAnywhereChanges={handleChanges}
            />
          </div>
          {!onlyRead && (
            <>
              {messageInitials && (
                <span className="errorInitials">{messageInitials}</span>
              )}
              <button
                id={`button-readonly-initials-${keyBatch}`}
                className="lot__button--readonly"
                type="button"
                onClick={handleInputOnlyRead}
              >
                {!readOnly ? "Usar sigla automática" : "Alterar sigla"}
              </button>
            </>
          )}
        </section>

        <section className="lot__content">
          <div className="lot__form">
            <section className="lot__textinput--container">
              <label>*Categoria</label>
              <Select
                placeholder="Selecione..."
                defaultMenuIsOpen={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                name="categoryMap"
                id={`select-lot-category-${keyBatch}`}
                options={Array.from(categories.values()).map((category) => ({
                  label: category.name,
                  value: category.id + "",
                  info: `${category.middle_weight} ${category.unit} - ${category.age_description}`,
                }))}
                getSelectedData={(option) => handleCategoryChange(option)}
                isDisabled={onlyRead}
                handleAnywhereChanges={handleChanges}
                isMulti
              />
            </section>
            <SelectWithInput
              onlyRead={false}
              selected={categoriesSelected}
              categoryMap={categories}
              handleChangeUA={handleUaTotalChanges}
              defaultValues={existedCategory}
              handleAnywhereChanges={handleChanges}
            />

            <section className="lot__textinput--container">
              <label>*UA total</label>
              <TextInput
                className="lot__ua--readonly"
                id={`textinput-ua-total-${keyBatch}`}
                readOnly
                defaultValue="0"
                name="total_ua"
                handleAnywhereChanges={handleChanges}
              />
            </section>

            <section className="lot__textinput--container">
              <label>*Pasto atual</label>
              <Select
                placeholder="Selecione..."
                isDisabled={onlyRead}
                defaultMenuIsOpen={false}
                closeMenuOnSelect={true}
                id={`select-lot-pasture-${keyBatch}`}
                name="current_micro_area"
                options={microAreaAvailable}
                noOptionsMessage={() => (
                  <span>Não existem pastos disponíveis</span>
                )}
                handleAnywhereChanges={handleChanges}
              />
            </section>

            {!onlyRead && isDeletable && (
              <div className="lot__button--delete">
                <ButtonOnlyTitle
                  title="- Remover esse lote"
                  theme="light"
                  onClick={eventRemove}
                />
              </div>
            )}
            <div className="lot__divider" />
          </div>
        </section>
      </AnimateHeight>
    </div>
  );
}
