import { useEffect, useState } from 'react'
import { Input } from '../../Input'
import { ICoords } from '../../../../../@types/GoogleMaps/ICoord'
import { ReactComponent as NextIcon } from "../../../../../assets/svg/icons/arrowNext.svg"
import * as geolib from 'geolib';

interface CreateMicroAreaProps {
  microArea: any;
  id: number;
  coordsMicroAreas: ICoords[];
  index: number;
  useForm: any;
  currentMicroArea: number;
  acreageUsed: Map<number, number>;
  setCurrentMicroArea: (n: number) => void;
}

export function CreateMicroArea({ microArea, id, coordsMicroAreas, currentMicroArea, setCurrentMicroArea, index, useForm, acreageUsed }: CreateMicroAreaProps) {

  const [initialsOnlyRead, setInitialsOnlyRead] = useState<boolean>(true)

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm;

  const acreageInput = watch(`microArea[${currentMicroArea}].acreage`)

  useEffect(() => {
    setValue(
      `microArea[${index}].initials`,
      `${(id + currentMicroArea + 1).toString().padStart(3, '0')}`,
    )

    setValue(`microArea[${index}].acreage`,
      Number(geolib.getAreaOfPolygon(coordsMicroAreas[index] as any) / 10000).toFixed(2))
  }, [id])

  useEffect(() => {
    acreageUsed.set(currentMicroArea + 1, Number(acreageInput || Number(geolib.getAreaOfPolygon(coordsMicroAreas[currentMicroArea] as any) / 10000)))
  }, [acreageInput])

  useEffect(() => {
    if (initialsOnlyRead) {
      setValue(
        `microArea[${currentMicroArea}].initials`,
        `${(id + currentMicroArea + 1).toString().padStart(3, '0')}`,
      )
    }
  }, [currentMicroArea, initialsOnlyRead])

  var erros = errors.microArea && errors.microArea.length > 0 && (errors.microArea as any)[currentMicroArea] ? {
    ...(errors.microArea as any)[currentMicroArea]
  } : {}

  return (
    <div hidden={currentMicroArea != index} style={{ maxWidth: 500 }}>
      <h2 className="formTitle">{`Pasto ${coordsMicroAreas.length != 1 ? `(${index + 1}/${coordsMicroAreas.length})` : ""}`}</h2>

      {coordsMicroAreas.length != 1 ?
        <span className="formSubTitle">Você pode visualizar as informações de cada área através das setas ou selecionando diretamente no mapa.</span>
        :
        ""
      }

      <div className='containerForm'>
        <div className='formCreateMicroArea'>

          <div>
            <label className="labelForm">
              Sigla
            </label>
            <Input
              name={`microArea[${index}].initials`}
              control={control}
              label={''}
              theme={initialsOnlyRead ? 'read-only' : 'normal'}
              disabled={initialsOnlyRead}
              labelPrefix={`${(microArea as IMicroArea).farm.initials}-${(microArea as IMicroArea).area.initials}-`}
              overrideStyles={{
                input: {
                  width: 200,
                }
              }}
              maxLength={4}
              errorText={erros?.initials?.message ? " " : ""}
            />
            <label
              onClick={() => setInitialsOnlyRead(!initialsOnlyRead)}
              className="initial--readOnly"
            >
              {initialsOnlyRead ? 'Alterar sigla' : 'Usar sigla automática'}
            </label>
          </div>

          <div>
            <label className="labelForm">
              Quantos hectares de área útil?
            </label>

            <div className="hectares__container">
              <Input
                name={`microArea[${index}].acreage`}
                theme={'normal'}
                label={''}
                type={'number'}
                control={control}
                disabled={false}
                prefixPosition={"right"}
                labelPrefix={"ha"}
                overrideStyles={{
                  prefixLabel: {
                    color: '#999999',
                    position: 'relative',
                    left: -220 + watch(`microArea[${index}].acreage`)?.length * 9.5
                  },
                  contentInput: {
                    background: "#fff"
                  },
                }}
                errorText={erros?.acreage ? " " : ""}
                defaultValue={Number(geolib.getAreaOfPolygon(coordsMicroAreas[index] as any) / 10000).toFixed(2)}
              />
            </div>
          </div>
        </div>

        {coordsMicroAreas.length > 1 &&
          <div className='currentMicroAreaContainer'>
            <div className='backMicroArea'>
              {currentMicroArea != 0 &&
                <NextIcon onClick={() => {
                  setCurrentMicroArea(currentMicroArea - 1)
                }} />
              }
            </div>
            <div className='nextMicroArea'>
              {currentMicroArea != coordsMicroAreas.length - 1 &&
                <NextIcon onClick={() => {
                  if (currentMicroArea != coordsMicroAreas.length - 1) {
                    setCurrentMicroArea(currentMicroArea + 1)
                  }
                }} />
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}
