import "./styles.css";
import { ReactComponent as FarmIcon } from "../../assets/svg/icons/farmIcon.svg";
import { ReactComponent as AreaIcon } from "../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as MicroAreaIcon } from "../../assets/svg/icons/grass.svg";
import { ReactComponent as PenIcon } from "../../assets/svg/icons/penEdit.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/icons/search.svg";
import { ReactComponent as AnimalsIcon } from "../../assets/svg/icons/animais.svg";
import { IOptionsEditOnboarding } from "../../contexts/EditOnboardingContext";
import ComponentChipOnlyView from "../Chips/ComponentChipLabel/Index";

type CardProps = {
  type: IOptionsEditOnboarding;
  initials: string;
  initialBatch?: string;
  name?: string;
  profile?: string;
  qtdArea?: number;
  qtdMicroArea?: number;
  qtdAnimals?: number;
  managementType?: any;
  onClick?: () => void;
};

export function Card({
  type,
  initials,
  initialBatch,
  name,
  profile,
  qtdArea,
  qtdMicroArea,
  qtdAnimals,
  onClick,
  managementType
}: CardProps) {
  const colorsMap = new Map()
  colorsMap.set("Contínuo", "#ff9501af")
  colorsMap.set("Rotacionado", "#ff6161af")
  colorsMap.set("Em faixa", "#0157ffaf")

  return (
    <div className="bodyCard" onClick={onClick} style={managementType ? {
      backgroundColor: colorsMap.get(managementType?.type?.name)
    } : {}}>
      {initials &&
        <section className="bodyCard__initials">
          {type == "farms" ? <FarmIcon /> : <></>}
          {type == "areas" ? <AreaIcon /> : <></>}
          {type == "micro-areas" ? <MicroAreaIcon /> : <></>}
          {type == "batches" ? <AreaIcon /> : <></>}
          <h3>{initials}</h3>
        </section>
      }

      {name && <h2 className="bodyCard__nameCard">{name}</h2>}

      {profile ? (
        <section className="bodyCard__profile">
          <h3>{profile}</h3>
        </section>
      ) : (
        ""
      )}

      <div className="bodyCard_infos">
        <>
          {initialBatch && (
            <ComponentChipOnlyView
              theme={"light"}
              label={initialBatch}
              variationStyles={{
                label: {
                  fontWeight: 400,
                  fontSize: '18px',
                  fontFamily: 'Fira Code'
                },
              }}
            />
          )}
          {qtdArea && qtdArea != 0 ? (
            <section className="bodyCard_infos--card">
              <AreaIcon />
              <h3>{qtdArea}</h3>
            </section>
          ) : (
            ""
          )}

          {qtdMicroArea && qtdMicroArea != 0 ? (
            <section className="bodyCard_infos--card">
              <MicroAreaIcon />
              <h3>{qtdMicroArea}</h3>
            </section>
          ) : (
            ""
          )}

          {qtdAnimals && qtdAnimals != 0 ? (
            <section className="bodyCard_infos--card">
              <AnimalsIcon />
              <h3>{qtdAnimals}</h3>
            </section>
          ) : (
            ""
          )}
        </>
      </div>
      <div className="edit__container">
        <div className="edit__button">
          {type == "batches" ? <SearchIcon /> : <PenIcon />}
        </div>
      </div>
    </div>
  );
}
